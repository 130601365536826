import "semantic-ui-css/semantic.min.css";
import { Form, Radio, Label } from "semantic-ui-react";
import React from "react";
import "./styles.css";

export const CTDForm = ({
  typeOfVisualization,
  CTDQuantities,
  showVariabels,
  onChangeTypeOfVisualization,
  onChangeCTDQuantities,
  OnClickdownloadFile,
}) => {
  return (
    <Form>
      <Form.Group grouped>
        <Form.Field>Type of Visualisation:</Form.Field>
        <Form.Field>
          <Radio
            label="Water depth (m)"
            name="visualisationRadioGroup"
            value="depthOfWater"
            checked={typeOfVisualization === "depthOfWater"}
            onChange={() => onChangeTypeOfVisualization("depthOfWater")}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Summary statistics"
            name="visualisationRadioGroup"
            value="statisticsOfQuantities"
            checked={typeOfVisualization === "statisticsOfQuantities"}
            onChange={() =>
              onChangeTypeOfVisualization("statisticsOfQuantities")
            }
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Variable value at depth"
            name="visualisationRadioGroup"
            value="depthBasedQuantities"
            checked={typeOfVisualization === "depthBasedQuantities"}
            onChange={() => onChangeTypeOfVisualization("depthBasedQuantities")}
          />
        </Form.Field>
      </Form.Group>
      {showVariabels ? (
        <Form.Group grouped>
          <Form.Field>Variables:</Form.Field>

          <Form.Field>
            <Radio
              label="Temperature"
              name="quantitiesRadioGroup"
              value="temprature"
              checked={CTDQuantities === "temprature"}
              onChange={() => onChangeCTDQuantities("temprature")}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Conductivity"
              name="quantitiesRadioGroup"
              value="conductivity"
              checked={CTDQuantities === "conductivity"}
              onChange={() => onChangeCTDQuantities("conductivity")}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Salinity"
              name="quantitiesRadioGroup"
              value="salinity"
              checked={CTDQuantities === "salinity"}
              onChange={() => onChangeCTDQuantities("salinity")}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label=" Density"
              name="quantitiesRadioGroup"
              value="density"
              checked={CTDQuantities === "density"}
              onChange={() => onChangeCTDQuantities("density")}
            />
          </Form.Field>
        </Form.Group>
      ) : null}
      <Form.Field>
        <Label className="downloadLink" onClick={() => OnClickdownloadFile()}>
          Download Data on the map
        </Label>
      </Form.Field>

      <Form.Field>
        <Label className="downloadLink" onClick={() => downloadAFile()}>
          Download jupyter notebook
        </Label>
      </Form.Field>
    </Form>
  );
};
const downloadAFile = () => {
  const link = document.createElement("a");
  link.href = "/notebooks/ctd_nb.ipynb";
  link.setAttribute("download", `ctd_nb.ipynb`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};
