import React, { useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Popup, Marker, Tooltip } from "react-leaflet";
import { Plot } from "./plot/Plot.js";
const Latitude = 53.261333;
const Longitude = -4.652833;

const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const tileAttr =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
//--------------------------------------------------------------
//                      custom Icon
//--------------------------------------------------------------
const TooltipFrame = ({ tooltipData }) => {
  const data = JSON.parse(tooltipData);
  return <> {data.date}</>;
};
//--------------------------------------------------------------
//                      custom Style
//--------------------------------------------------------------
export const CTDMap = ({ ctdData }) => {
  const [att, setAtt] = useState(null);
  //console.log(ctdData.features[0]);
  const customIcon = (htmlICONCode) =>
    L.divIcon({
      className: "custom-div-icon",
      html: htmlICONCode,
    });
  return (
    <MapContainer center={[Latitude, Longitude]} zoom={7}>
      <TileLayer url={tileUrl} attribution={tileAttr} />
      {ctdData.features.map((d, index) => (
        <Marker
          key={index}
          position={[d.properties.lat, d.properties.lon]}
          icon={customIcon(d.properties.icon)}
          eventHandlers={{
            click: () => {
              setAtt(d.properties);
            },
          }}
        >
          <Tooltip position={[d.properties.lat, d.properties.lon]}>
            <TooltipFrame tooltipData={d.properties.tooltip}></TooltipFrame>
          </Tooltip>
        </Marker>
      ))}

      {att && (
        <Popup
          position={[att.lat, att.lon]}
          onClose={() => {
            setAtt(null);
          }}
        >
          <Plot pupupData={att.popup} />
        </Popup>
      )}
    </MapContainer>
  );
};
