import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
//import { Image } from "react-native";

//import { Nav } from "react-bootstrap";
//import Button from "react-bootstrap/Button";

import {
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import "react-pro-sidebar/dist/css/styles.css";
//import "./sidebar.css";
import "./custom.scss";

import { CTDForm } from "./ctdFom";

export const Sidebar = ({
  typeOfVisualization,
  CTDQuantities,
  showVariabels,
  onChangeTypeOfVisualization,
  onChangeCTDQuantities,
  OnClickdownloadFile,
}) => {
  return (
    <ProSidebar>
      <SidebarHeader>
        <center>
          <img id="logo" src="/imardisLogo.png" alt="imardis" />
          <h2 id="analyzer_logo">Analyser</h2>
        </center>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          <center>
            <MenuItem>
              <h2>Dashboard</h2>
            </MenuItem>
          </center>
          <SubMenu title="CTD">
            <MenuItem>
              <CTDForm
                typeOfVisualization={typeOfVisualization}
                CTDQuantities={CTDQuantities}
                showVariabels={showVariabels}
                onChangeTypeOfVisualization={onChangeTypeOfVisualization}
                onChangeCTDQuantities={onChangeCTDQuantities}
                OnClickdownloadFile={OnClickdownloadFile}
              />
            </MenuItem>
          </SubMenu>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        {/**
         *  You can add a footer for the sidebar ex: copyright
         */}
      </SidebarFooter>
    </ProSidebar>
  );
};
