import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value}>
        <span>{value}</span>
      </Handle>
    </SliderTooltip>
  );
};
const handleStyle = [
  {
    height: 35,
    width: 25,
    marginLeft: -10,
    backgroundColor: "#075DFF",
    border: "none",
    borderRadius: 3,
    boxShadow: "none !imporatnt",
  },
];

const trackStyle = [{ backgroundColor: "blue", height: "100" }];

const range = (min, max, step) => {
  var val = min;
  var valDict = {};
  valDict[min] = min.toString();
  while (val < max) {
    val = val + step;
    valDict[val] = val.toString();
  }
  valDict[max] = max.toString();
  return valDict;
};

export const DepthSlider = ({ depth_min, depth_max, setDepth }) => {
  const marks = range(Math.floor(depth_min), Math.ceil(depth_max), 5);
  return (
    <Range
      vertical
      reverse
      min={depth_min}
      max={depth_max}
      step={1}
      defaultValue={[depth_min]}
      marks={marks}
      onAfterChange={setDepth}
      tipFormatter={(value) => `${value}`}
      handle={handle}
      handleStyle={handleStyle}
      included={false}
      trackStyle={trackStyle}
      //railStyle={{ backgroundColor: "red" }}
    />
  );
};
