import { useState, useEffect } from "react";

export const InitSliders = (url) => {
  const [initDepthRange, setInitDepthRange] = useState(null);
  const [initDateRange, setInitDateRange] = useState(null);
  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((bounds) => {
        setInitDateRange([
          new Date(bounds.datetime_min).getTime(),
          new Date(bounds.datetime_max).getTime(),
        ]);
        setInitDepthRange([bounds.max_depth_min, bounds.max_depth_max]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [url]);
  return { initDateRange, initDepthRange };
};
