export const AxisBottom = ({
  xScale,
  innerHeight,
  tickFormat,
  tickOffset = 3,
}) =>
  xScale.ticks().map((tickValue) => (
    <g
      className="tick"
      key={tickValue}
      transform={`translate(${xScale(tickValue)},0)`}
    >
      <line y2={innerHeight} />
      {/* x1 = {xScale(tickValue)} 
                y1 = {0} 
                x2 = {xScale(tickValue)} 
                y2 = {innerHeight}*/}
      <text
        style={{ textAnchor: "middle" }}
        dy="0.71em"
        y={innerHeight + tickOffset}
      >
        {tickFormat(tickValue)}
      </text>
    </g>
  ));
