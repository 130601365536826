import "./App.css";
import "./styles.css";
//import { BrowserRouter as Router } from "react-router-dom";
//import { Container, Grid, Header, List } from "semantic-ui-react";
//import { Container, Col, Row } from "react-bootstrap";

import { CTDMap } from "./CTDMap";
import { Sidebar } from "./sidebar";
import { DepthSlider } from "./DepthSlider";
import { DateSlider } from "./DateSlider";

import { useState } from "react";
import moment from "moment";
import { GetData } from "./getData";
import { InitSliders } from "./initSliders";
import { DownloadFile } from "./downloadFile";

//-----------------------------------------------------------------

//state
//--------------------------------------
//date Slider
//--------------------------------------
const date_min = new Date("01-01-2000").getTime();
const date_max = new Date().getTime();
const date_step = 1000 * 60 * 60 * 24; //*7(week)
const formatDate = (value) => {
  const date = new Date(value);
  return moment(date).format("ll");
};

//const url = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000/";
const url = "https://api-analyser.imardis.org";
const downloadUrl = "https://api-analyser.imardis.org/downloadDataFrame";

//const url =
//  "http://imardis-analyser-api.eba-nnsu4zdp.eu-west-2.elasticbeanstalk.com/";
//const downloadUrl =
// "http://imardis-analyser-api.eba-nnsu4zdp.eu-west-2.elasticbeanstalk.com/downloadDataFrame/";

//const url = "http://127.0.0.1:5000/";
//const downloadUrl = "http://127.0.0.1:5000/downloadDataFrame";

var ctdData = null;
var showDepthSlider = false;
var showVariabels = false;
// typeOfVisualization =[depthOfWater, statisticsOfQuantities, depthBasedQuantities]
// CTDQuantities = [temprature, conductivity, salinity, density]

export const App = () => {
  const [typeOfVisualization, setTypeOfVisualization] =
    useState("depthOfWater");

  const [CTDQuantities, setCTDQuantities] = useState("temprature");

  const [dateRange, setDateRange] = useState([date_min, date_max]);

  const [depth, setDepth] = useState([0]);

  const { initDateRange, initDepthRange } = InitSliders(url);

  //const [ctdData, setCtdData] = useState(null);

  //------------------------------------------------
  //fetch ctddata from python to display on the map
  //------------------------------------------------
  const filters = JSON.stringify({
    typeOfVisualization: typeOfVisualization,
    CTDQuantities: CTDQuantities,
    dateRange: dateRange,
    depth: depth,
  });
  console.log(filters);
  ctdData = GetData(url, filters);

  if (!ctdData) {
    return <pre>Loading...</pre>;
  }

  //------------------------------------------------
  //fetch ctddata from python to download
  //------------------------------------------------
  //console.log(filters);
  const onClickDownloadFile = () => {
    DownloadFile(downloadUrl, filters);
  };
  //------------------------------------------------

  switch (typeOfVisualization) {
    case "depthBasedQuantities":
      showDepthSlider = true;
      showVariabels = true;
      break;
    case "statisticsOfQuantities":
      showDepthSlider = false;
      showVariabels = true;
      break;
    case "depthOfWater":
      showDepthSlider = false;
      showVariabels = false;
      break;
    default:
  }

  //-----------------------------------
  return (
    <>
      <div id="sidebar_id">
        {
          <Sidebar
            typeOfVisualization={typeOfVisualization}
            CTDQuantities={CTDQuantities}
            showVariabels={showVariabels}
            onChangeTypeOfVisualization={setTypeOfVisualization}
            onChangeCTDQuantities={setCTDQuantities}
            OnClickdownloadFile={onClickDownloadFile}
          />
        }
      </div>
      <div id="content_id">
        <div className="col" id="depthSlider_id">
          {showDepthSlider ? (
            <DepthSlider
              depth_min={initDepthRange[0]}
              depth_max={initDepthRange[1]}
              setDepth={setDepth}
            />
          ) : null}
        </div>
        <div className="col" id="dateSlider_id">
          <DateSlider
            style={{ display: "none" }}
            min={initDateRange[0]}
            max={initDateRange[1]}
            start={initDateRange}
            step={date_step}
            onUpdate={setDateRange}
            format={formatDate}
          />
        </div>
        <div id="map_id">
          <CTDMap ctdData={ctdData} />
        </div>
      </div>
    </>
  );
};
