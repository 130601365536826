import { useEffect, useState } from "react";

export const GetData = (url, filters, method = "POST") => {
  const [data, setData] = useState(null);
  // useEffect is used to tell react do s.t. just once!
  // fetching data just one time! not continuesly.

  useEffect(() => {
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: filters,
    };

    fetch(url, options)
      .then((res) => res.json())
      .then(setData) //data => {setData(data);}
      .catch((err) => {
        console.log(err);
      });
  }, [url, filters, method]);
  return data;
};
