import React, { useState, useEffect } from "react";
import ReactDropdown from "react-dropdown";
import "react-dropdown/style.css";

import { scaleLinear, extent } from "d3"; //format
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";
import { Marks } from "./Marks";

//import { Dropdown } from './Dropdown';
// a dataset containing named CSS colors from MDN: CSS Colors
// https://developer.mozilla.org/en-us/docs/Web/css/color_value

const width = 900;
const menuHeight = 100;
const height = 500 - menuHeight;
const margin = { top: 20, right: 30, bottom: 65, left: 90 };
const xAxisLabelOffset = 60;
const yAxisLabelOffset = 60;
const xTickOffset = 10;
const yTickOffset = 15;
const circleRadius = 3;

const attributes = [
  { value: "Pressure/dbar", label: "Pressure" },
  { value: "c0S/m", label: "Conductivity" },
  { value: "density00", label: "Density" },
  { value: "sal00", label: "Salinity" },
  { value: "t090C", label: "Temperature" },
  { value: "depth", label: "Depth" },
];

const getLabel = (value) => {
  for (let i = 0; i < attributes.length; i++) {
    if (attributes[i].value === value) {
      return attributes[i].label;
    }
  }
};

export const Plot = (popupData) => {
  const [data, setData] = useState(null);
  const reformData = (d) => {
    const dataLength = d["Pressure/dbar"].length;
    var i;
    var reformed_data = [];
    for (i = 0; i < dataLength; i++) {
      reformed_data.push({
        "Pressure/dbar": d["Pressure/dbar"][i],
        "c0S/m": d["c0S/m"][i],
        density00: d["density00"][i],
        sal00: d["sal00"][i],
        t090C: d["t090C"][i],
        depth: d["depth"][i],
      });
    }
    return reformed_data;
  };
  useEffect(() => {
    const data = reformData(JSON.parse(popupData.pupupData));
    setData(data);
  }, [popupData.pupupData]);

  const initialXAttribute = "t090C";
  const [xAttribute, setXAttribute] = useState(initialXAttribute);
  const xValue = (d) => d[xAttribute];
  const xAxisLabel = getLabel(xAttribute);

  const initialYAttribute = "Pressure/dbar";
  const [yAttribute, setYAttribute] = useState(initialYAttribute);
  const yValue = (d) => d[yAttribute];
  const yAxisLabel = getLabel(yAttribute);

  if (!data) {
    return <pre>Loading...</pre>;
  }

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;
  //const siFormat = format(".2s");
  const xAxisTickFormat = (tickValue) => tickValue; //siFormat(tickValue).replace("G", "B");

  const xScale = scaleLinear()
    .domain(extent(data, xValue))
    .range([0, innerWidth])
    .nice();

  const yScale = scaleLinear()
    .domain(extent(data, yValue))
    .range([0, innerHeight]);

  return (
    <>
      <div className="menus-container">
        <span className="dropdown-label">X</span>
        <ReactDropdown
          options={attributes}
          value={xAttribute}
          onChange={({ value }) => setXAttribute(value)}
        />
        <span className="dropdown-label">Y</span>
        <ReactDropdown
          options={attributes}
          value={yAttribute}
          onChange={({ value }) => setYAttribute(value)}
        />
      </div>
      <svg width={width} height={height}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          <AxisBottom
            xScale={xScale}
            innerHeight={innerHeight}
            tickFormat={xAxisTickFormat}
            tickOffset={xTickOffset}
          />
          <text
            className="axis-label"
            textAnchor="middle"
            transform={`translate(${-yAxisLabelOffset},${
              innerHeight / 2
            }) rotate(-90)`}
          >
            {yAxisLabel}
          </text>
          <AxisLeft
            yScale={yScale}
            innerWidth={innerWidth}
            tickOffset={yTickOffset}
          />
          <text
            className="axis-label"
            x={innerWidth / 2}
            y={innerHeight + xAxisLabelOffset}
            textAnchor="middle"
          >
            {xAxisLabel}
          </text>
          <Marks
            data={data}
            xScale={xScale}
            yScale={yScale}
            xValue={xValue}
            yValue={yValue}
            tooltipFormat={xAxisTickFormat}
            circleRadius={circleRadius}
          />
        </g>
      </svg>
    </>
  );
};
