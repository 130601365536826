export const DownloadFile = (url, filters, method = "POST") => {
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: filters,
  };
  fetch(url, options)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `ctd_data.pkl`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
    });
};
