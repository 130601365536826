import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
export const DateSlider = ({
  min,
  max,
  start,
  step,
  orientation = "horizontal",
  onUpdate,
  format = null,
}) => {
  return (
    <Nouislider
      range={{ min: min, max: max }}
      start={start}
      step={step}
      connect
      tooltips={[
        {
          to: format,
        },
        {
          to: format,
        },
      ]}
      onChange={onUpdate}
      orientation={orientation}
    />
  );
};
